<template>
  <b-row>
    <b-col cols="3">
      <form-setting v-on:demoUpdated="refreshPreview" />
    </b-col>
    <b-col cols="9">
      <form-preview ref="formPreview" :setting-id="this.colorSettingId" :reload-hash="this.reloadHash" />
    </b-col>
  </b-row>
</template>

<script>
import FormSetting from "@/view/pages/form-designer/FormSetting.vue";
import FormPreview from "@/view/pages/form-designer/FormPreview.vue";
import generateHash from 'random-hash';

export default {
  data() {
    return {
      colorSettingId: "",
      reloadHash: "1"
    }
  },
  name: "form-designer",
  components: {
    FormPreview,
    FormSetting
  },
  methods: {
    refreshPreview: function(colorSettingId){
      this.colorSettingId = colorSettingId;
      this.reloadHash = generateHash();
    }
  }
}
</script>

<style>

</style>