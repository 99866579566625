<template>
  <div class="card card-custom card-stretch gutter-b bg-light">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
          >Tailor Your Colours</span
        >
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <b-row>
        <b-col>
          <b-form-group label="Select Saved Setting" v-slot="{ ariaDescribedby }">
            <b-form-select
              v-model="activeSettingId"
              :options="savedSettings"
              :aria-describedby="ariaDescribedby"
              @change="onSettingChange"
              buttons
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col>
          <b-form-group label="Setting Title">
            <b-form-input v-model="colorSetting.settingName" placeholder="Enter setting title"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-for="(setting, idx) in settingList">
        <div class="d-flex align-items-center mb-10" v-bind:key="idx">
          <div class="color-preview m-0 mr-4" v-bind:style="setting.colorPreview"></div>
          <div class="d-flex flex-column flex-grow-1">
            <span class="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
              {{ setting.name }}
            </span>
            <span class="text-muted font-weight-bold" v-if="setting.description">
              {{ setting.description }}
            </span>
          </div>
          <div class="btn-group">
            <b-button
              :id="`setting-popover-${idx}`"
              class="btn btn-sm btn btn-clean btn-hover-light-primary btn-sm btn-icon dropdown-toggle-no-caret"
              ><i class="ki ki-bold-more-hor"></i></b-button>
          </div>
          
          <b-popover
            ref="popover"
            :target="`setting-popover-${idx}`"
            triggers="click"
            placement="bottomleft"
            @show="onPopoverShow"
            @hidden="deleteDemoSetting"
            :content="`Placement ${idx}`"
            custom-class="setting-popover"
          >
            <template #title>
              <b-row class="m-0">
                <b-col>Setting</b-col>
                <b-col cols="2">
                  <b-icon class="close-popover" icon="x-circle-fill" font-scale="1" variant="primary" @click="closeOtherPopover"></b-icon>
                </b-col>
              </b-row>
            </template>
            <div>
              <b-row class="mb-1">
                <b-col>
                  <template v-for="(item, i) in setting.fields">
                    <div :key="i">
                      <div v-if="item.type == 'toggle'">
                        <b-form-group :label="item.label" >
                          <toggle-button
                            v-model="colorSetting[item.key]"
                            :sync="true"
                            @input="updateDemoSetting" 
                            @click.native="hideColorPicker"
                          />  
                        </b-form-group>
                      </div>

                      <div v-if="item.type == 'file'">
                        <b-form-group :label="item.label" >
                          <b-form-file
                            @click.native="hideColorPicker"
                            v-model="previewImage"
                            :state="Boolean(previewImage)"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                          ></b-form-file>
                          <div v-if="colorSetting[item.key]" class="logo-container">
                            <div class="mt-3">Current logo:</div>
                            <img
                              :src="$store.getters.logoPath"
                              class="ml-3 mt-4 h-50px align-self-center"
                              alt=""
                            />
                            <div class="remove-logo" @click="removeLogo">
                            <b-iconstack font-scale="2">
                              <b-icon stacked icon="circle-fill"></b-icon>
                              <b-icon stacked icon="x" variant="light"  scale="0.9"></b-icon>
                            </b-iconstack>
                            </div>
                          </div>
                        </b-form-group>
                      </div>

                      <b-form-group
                        v-if="item.type == 'text'"
                        :label="item.label"
                        :label-for="`text-input-${i}`"
                      >
                        <b-form-input v-model="colorSetting[item.key]" @input="updateDemoSetting" @click.native="hideColorPicker"></b-form-input>
                      </b-form-group>

                      <b-form-group
                        v-if="item.type == 'textArea'"
                        :label="item.label"
                        :label-for="`text-input-${i}`"
                      >
                        <b-form-textarea v-model="colorSetting[item.key]" @input="updateDemoSetting" @click.native="hideColorPicker" rows="4"></b-form-textarea>
                      </b-form-group>

                      <div v-if="item.type == 'color'">
                        <b-form-group
                          :label="item.label"
                          :label-for="`color-input-${i}`"
                        >
                          <b-form-input v-model="colorSetting[item.key]" @click.native="updateIndex(idx, i)"></b-form-input>
                        </b-form-group>
                      </div>
                    </div>
                  </template>
                  <b-form-group v-if="isColorPickerOpen" class="font-weight-bolder mt-4" label="Select color here:">
                    <custom-colorpicker v-model="colorSetting[settingList[settingIndex].fields[fieldIndex].key]" v-on:colorUpdate="updateDemoSetting" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col>
                  <b-button v-if="setting.dataType == 'formData'" class="mt-3" variant="primary" block @click="uploadImage">Save</b-button>
                  <b-button v-if="setting.dataType == 'json'" class="mt-3" variant="success" block @click="saveSetting">Save</b-button>    
                </b-col>
              </b-row>
            </div>
          </b-popover>
        </div>
        <!--end:Item-->
      </template>
      <b-row v-if="activeSettingId">
        <b-col class="text-right mt-3">
          <b-icon icon="trash" variant="danger" v-on:click="deleteColorSetting" role="button" font-scale="2"></b-icon>
        </b-col>
      </b-row>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import CustomColorpicker from '@/component/CustomColorpicker.vue';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import _ from "lodash";

export default {
  name: "form-setting",
  components: {
    CustomColorpicker
  },
  data() {
    return {
      activeSettingId: "",
      isColorPickerOpen: false,
      settingIndex: 0,
      fieldIndex: 0,
      colorSetting: {
        _id: "",
        headerBackground: "#ffffff",
        headerEnabled: false,
        logoPath: "",
        phoneNumberColor: "#000000",
        headerTitleColor: "#000000",
        phoneNumberText: "",
        headerTitleText: "",
        tabsBackground: "#077ee2",
        inactiveTabsBackground: "#077ee2",
        elementColourBlock: "#077ee2",
        buttonBackground: "#077ee2",
        mainButtonBackground: "#077ee2",
        infoBubbleBackground: "#077ee2",
        mainBackground: "#ffffff",
        optionBoxBackground: "#EFF8FF",
        writingColourBlock: "#020A8C",
        moreLessInfoBackground: "#077EE2",
        privacyAndTos: false,
        agreementMessage: "",
        privacyLink: "",
        tosLink: "",
        gettingQuoteText: "",
        thankYouMessageText: "",
        settingName: "",
        type: "live",
        twoPageQuote: false,
        externalApplicationLink: "",
        externalApplication: false,
        applyOnlineBtnText: "Apply Online",
        startApplicationBtnText: "Start Application",
        hasSecondaryText: true,
        startApplicationSecondaryBtnText: "Can be completed in 5 minutes",
        applyBtnText: "Apply"
      },
      previewImage: null,
      demoSettingId: ""
    }
  },
  computed: {
    settingList: {
      get() {
        return [
          {
            name: "Header Block",
            description: "",
            colorPreview: false,
            dataType: "formData",
            fields: [
              {
                type: "toggle",
                key: "headerEnabled",
                label: "Enabled header block"
              },
              {
                type: "text",
                key: "headerTitleText",
                label: "Set header text"
              },
              {
                type: "color",
                key: "headerTitleColor",
                label: "Text color"
              },
              {
                type: "file",
                key: "logoPath",
                label: "Select logo"
              },
              {
                type: "color",
                key: "headerBackground",
                label: "Set background color"
              }
            ]
          },
          {
            name: "Tabs",
            description: "",
            colorPreview:  false,
            dataType: "json",
            fields: [
              {
                type: "color",
                key: "tabsBackground",
                label: "Set text color"
              },
              {
                type: "color",
                key: "inactiveTabsBackground",
                label: "Set tab background"
              }
            ]
          },
          {
            name: "Colour Block",
            description: "Buttons, info bubble, and writing colour",
            colorPreview: {
              backgroundColor: this.colorSetting.elementColourBlock || "#ffffff"
            },
            dataType: "json",
            fields: [
              {
                type: "color",
                key: "elementColourBlock",
                label: "Set color"
              }
            ]
          },
          {
            name: "Buttons",
            description: "",
            colorPreview: false,
            dataType: "json",
            fields: [
              {
                type: "color",
                key: "buttonBackground",
                label: "Set form button color"
              },
              {
                type: "color",
                key: "mainButtonBackground",
                label: "Set primary button color"
              },
              {
                type: "color",
                key: "infoBubbleBackground",
                label: "Set info bubble color"
              }
            ]
          },
          {
            name: "Main Background",
            description: "",
            colorPreview: {
              backgroundColor: this.colorSetting.mainBackground || "#ffffff"
            },
            dataType: "json",
            fields: [
              {
                type: "color",
                key: "mainBackground",
                label: "Set background color"
              }
            ]
          },
          {
            name: "Option Box",
            description: "",
            colorPreview: {
              backgroundColor: this.colorSetting.optionBoxBackground || "#ffffff"
            },
            dataType: "json",
            fields: [
              {
                type: "color",
                key: "optionBoxBackground",
                label: "Set background color"
              }
            ]
          },
          {
            name: "Writing Colour 2",
            description: "Result column headers and button style 2",
            colorPreview: {
              backgroundColor: this.colorSetting.writingColourBlock || "#ffffff"
            },
            dataType: "json",
            fields: [
              {
                type: "color",
                key: "writingColourBlock",
                label: "Set color"
              }
            ]
          },
          {
            name: "More/less Info",
            description: "",
            colorPreview: {
              backgroundColor: this.colorSetting.moreLessInfoBackground || "#ffffff"
            },
            dataType: "json",
            fields: [
              {
                type: "color",
                key: "moreLessInfoBackground",
                label: "Set color"
              }
            ]
          },
          {
            name: "Phone Number",
            description: "",
            dataType: "json",
            fields: [
              {
                type: "text",
                key: "phoneNumberText",
                label: "Display phone number"
              }
            ]
          },
          {
            name: "Getting Quote",
            description: this.colorSetting.gettingQuoteText || "",
            colorPreview: false,
            dataType: "json",
            fields: [
              {
                type: "text",
                key: "gettingQuoteText",
                label: "Set quote loading text"
              }
            ]
          },
          {
            name: "Form Wording",
            description: "Modify button wording",
            colorPreview: false,
            dataType: "json",
            fields: [
              {
                type: "text",
                key: "applyOnlineBtnText",
                label: "Set 'Apply Online' button text"
              },
              {
                type: "toggle",
                key: "externalApplication",
                label: "External application"
              },
              {
                type: "text",
                key: "externalApplicationLink",
                label: "External application URL"
              },
              {
                type: "text",
                key: "startApplicationBtnText",
                label: "Set 'Start Application' button text"
              },
              {
                type: "toggle",
                key: "hasSecondaryText",
                label: "Show secondary text"
              },
              {
                type: "text",
                key: "startApplicationSecondaryBtnText",
                label: "Set 'Start Application' button secondary text"
              },
              {
                type: "text",
                key: "applyBtnText",
                label: "Set 'Apply' button text"
              }
            ]
          },
          {
            name: "Thank You Message",
            description: "Successful application message",
            colorPreview: false,
            dataType: "json",
            fields: [
              {
                type: "textArea",
                key: "thankYouMessageText",
                label: "Set quote thank you message"
              }
            ]
          },
          {
            name: "Customer Agreement",
            description: "",
            colorPreview: false,
            dataType: "json",
            fields: [
              {
                type: "toggle",
                key: "privacyAndTos",
                label: "Enabled customer agreement block"
              },
              {
                type: "textArea",
                key: "agreementMessage",
                label: "Set the link message for customer agreement"
              },
              {
                type: "text",
                key: "privacyLink",
                label: "Set the link for you Privacy Policy page"
              },
              {
                type: "text",
                key: "tosLink",
                label: "Set the link for you Terms and Condition page"
              }
            ]
          },
          {
            name: "Two-Page Quote",
            description: "Quote on second page",
            colorPreview: false,
            dataType: "json",
            fields: [
              {
                type: "toggle",
                key: "twoPageQuote",
                label: "Start quoting on second page"
              }
            ]
          }
        ]
      }
    },
    savedSettings: {
      get() {
        var settings = [{ 'text': 'Create New Setting', 'value' : '' }];
        
        this.$store.state.formDesign.colorSettingList.forEach(colorSetting => {
          settings.push({
            'text' : colorSetting.settingName,
            'value' : colorSetting._id
          });
        });

        return settings;
      }
    }
  },
  methods: {
    hideColorPicker: function(){
      this.isColorPickerOpen = false;
    },
    updateIndex: function(settingIndex, fieldIndex){
      this.isColorPickerOpen = true;
      this.settingIndex = settingIndex;
      this.fieldIndex = fieldIndex;
    },
    saveSetting: function(){
      this.$refs.popover.map(popover => popover.$emit('close'));
      this.$store.dispatch("updateColorSetting", this.colorSetting)
        .then(colorSettingId => {
          this.activeSettingId = colorSettingId;
          this.refreshColorSetting();
          this.$store.dispatch("getColorSettingList");
        });
    },
    onPopoverShow: function(){
      this.closeOtherPopover();
      this.createDemoSetting();
    },
    closeOtherPopover: function(){
      this.isColorPickerOpen = false;
      this.$refs.popover.map(popover => popover.$emit('close'));
    },
    createDemoSetting: function(){
      var demoSetting = Object.assign({}, _.cloneDeep(this.colorSetting));
      demoSetting["type"] = "temporary";
      delete demoSetting["_id"];
      
      this.$store.dispatch("updateColorSetting", demoSetting)
      .then(demoSettingId => {
        this.demoSettingId = demoSettingId;
      });
    },
    updateDemoSetting: function(){
      setTimeout(() => {
        var demoSetting = Object.assign({}, _.cloneDeep(this.colorSetting));
        demoSetting["_id"] = this.demoSettingId;
        demoSetting["type"] = "temporary";

        this.$store.dispatch("updateColorSetting", demoSetting)
        .then(demoSettingId => {
          console.log(demoSettingId);
          this.$emit("demoUpdated", demoSettingId);
        });
      },500);
      
    },
    deleteDemoSetting: function(){
      this.$store.dispatch("deleteColorSetting", this.demoSettingId)
      .then(() => {
        this.demoSettingId = "";
        this.$emit("demoUpdated", this.colorSetting._id);
        this.refreshColorSetting();
      });
    },
    uploadImage: function(){
      const formData = new FormData();

      for(const [key, value] of Object.entries(this.colorSetting)){
        formData.append(key, value);
      }
      
      if(this.previewImage){  
        formData.append("logoPath", this.previewImage);
      }

      console.log(formData);

      this.$store.dispatch("updateColorSetting", formData)
      .then(colorSettingId => {
        this.activeSettingId = colorSettingId;
        this.refreshColorSetting();
        this.$store.dispatch("getColorSettingList");
        this.$refs.popover.map(popover => popover.$emit('close'));
      });

      
    },
    removeLogo: function(){
      console.log(this.colorSetting);
      var colorSetting = {...this.colorSetting};
      colorSetting['logoPath'] = "";
      this.$store.dispatch("updateColorSetting", colorSetting)
        .then(() => {
          this.refreshColorSetting();
        });
    },
    onSettingChange: function(){
      this.refreshColorSetting();
    },
    refreshColorSetting: function(){
      console.log("Active Setting ID: "+this.activeSettingId);

      this.$store.dispatch("getUserColorSettingById", { colorSettingId: this.activeSettingId, type: "live"}).then(colorSetting => {
        console.log(colorSetting);
        if(colorSetting){
          for(const [key, value] of Object.entries(colorSetting)){
            this.colorSetting[key] = value;
          }
        } else {
          
          this.colorSetting = {
            _id: "",
            headerBackground: "#ffffff",
            headerEnabled: false,
            logoPath: "",
            phoneNumberColor: "#000000",
            headerTitleColor: "#000000",
            phoneNumberText: "",
            headerTitleText: "",
            tabsBackground: "#077ee2",
            inactiveTabsBackground: "#077ee2",
            elementColourBlock: "#077ee2",
            buttonBackground: "#077ee2",
            mainBackground: "#ffffff",
            optionBoxBackground: "#EFF8FF",
            writingColourBlock: "#020A8C",
            moreLessInfoBackground: "#077EE2",
            gettingQuoteText: "",
            thankYouMessageText: "",
            type: "live",
            twoPageQuote: false,
            applyOnlineBtnText: "Apply Online",
            startApplicationBtnText: "Start Application",
            hasSecondaryText: true,
            startApplicationSecondaryBtnText: "Can be completed in 5 minutes",
            applyBtnText: "Apply"
          }
        }
        
        console.log("Update Demo: "+this.colorSetting._id);
        this.$emit("demoUpdated", this.colorSetting._id);
      });
    },
    deleteColorSetting: function(){
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteColorSetting", this.activeSettingId)
          .then(() => {
            this.activeSettingId = "";
            this.refreshColorSetting();
            this.$store.dispatch("getColorSettingList");
          });
          
          this.$swal({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: "Setting removed."
          });   
        }
      });


      
    }
  },
  mounted: function(){
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Form Designer" }]);
    this.$store.dispatch("getColorSettingList");
  }
}
</script>

<style lang="scss">
.color-preview {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 0.675rem;
  flex-shrink: 0 !important;
}

.setting-popover {
  min-width: 315px;

  .popover-header {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.uploading-image{
  display:flex;
}

.close-popover {
  font-size: 20px;
  cursor: pointer;
}

.logo-container {
  position: relative;
}

.remove-logo {
  position: absolute;
  top: 20px;
  left: 0;
  cursor: pointer;
}
</style>