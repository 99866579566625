<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
          >Live Preview</span
        >
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body p-0">
      <b-row class="midblue-text loader-container" align-v="center" v-if="isLoading">
        <b-col>
          <div class="text-center mb-4">
            <b-spinner class="m-5" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
          </div>
          <h4 class="mb-4" align="center">Loading live view</h4>
        </b-col>
      </b-row>
      <div id="wrap">
        <iframe
          id="preview-screen"
          ref="preview"
          :src="this.baseSrc"
          v-on:load="previewLoaded"
          frameborder="0">
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
const querystring = require('querystring');
// const url = require('url');

export default {
  props: ["settingId", "reloadHash"],
  data() {
    return {
      scrollOptions: {
        height: '100%',
        size: 0
      },
      isLoading: true,
      baseSrc: "/pmi-quote?displayMode=demo"
    }
  },
  watch: {
    reloadHash: function(){
      this.reloadPreview();
    },
    settingId: function(){
      this.reloadPreview();
    }
  },
  computed: {
    queryString: {
      get(){
        var query = {
          displayMode: "demo",
          colorSettingId: this.settingId,
          reloadHash: this.reloadHash
        }
        return querystring.encode(query);
      }
    }
  },
  methods: {
    reloadPreview: function(){
      // var href = this.$refs.preview.contentWindow.location.href;
      // var baseHash = href.split("?").shift();
      // var updatedHref = baseHash+"?"+this.queryString;

      // console.log(updatedHref);
      // this.$refs.preview.contentWindow.location.href = updatedHref;

      var transmitData = {
        messageType: "reloadPreview",
        payload: {
          colorSettingId: this.settingId
        }
      }

      console.log("Reload preview called");
      this.$refs.preview.contentWindow.postMessage(JSON.stringify(transmitData),"*");
    },
    previewLoaded: function(){
      this.isLoading = false;
      this.reloadPreview();
    }
  }
}
</script>

<style>
.loader-container {
  height: 900px;
  width: 100%;
  position: absolute;
}

#wrap {
  overflow: hidden;
  height: 1000px;
}

#preview-screen {
  width: 133.5%;
  height: 1335px;
  -webkit-transform: scale(0.75, 0.75) translate(-16.5%, -16.5%);
  -moz-transform: scale(0.75, 0.75) translate(-16.5%, -16.5%);
  -ms-transform: scale(0.75, 0.75) translate(-16.5%, -16.5%);
  transform: scale(0.75, 0.75) translate(-16.5%, -16.5%);
}
</style>